import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="unordered">
            <strong>
              <strong>Terms and Conditions</strong>
            </strong>
          </h3>
          <p>Anet Jursová - ID: 19385277</p>
          <h4 id="unordered">
            <strong>
              <strong>Order</strong>
            </strong>
          </h4>
          <p>
            Orders can be placed via email, an Instagram message, or a Facebook
            message. By placing an order, the client confirms acceptance of
            these terms and understands and agrees to the photographer's
            artistic approach and style. The order becomes binding upon mutual
            agreement on the shooting date between the client and the
            photographer.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Cancellation of Reservation</strong>
            </strong>
          </h4>
          <p>
            If the client cannot attend the agreed-upon photo session for any
            reason and wishes to cancel or reschedule, it is their
            responsibility to promptly inform the photographer via email or
            phone. In such cases, the photographer is entitled to reimbursement
            of incurred costs in full. The photographer may cancel or reschedule
            the photo session for significant reasons (illness, injury, etc.).
            The client will be informed promptly, with an offer for the next
            available date.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Alternative Date</strong>
            </strong>
          </h4>
          <p>
            The client acknowledges that when requesting an alternative date, it
            will be offered based on the current availability. There may be
            instances, especially for newborn sessions, where a suitable date
            might not be available.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Client's Late Arrival</strong>
            </strong>
          </h4>
          <p>
            If the client is running late, they should inform the photographer
            by phone. Late arrival does not extend the scheduled shooting time.
            If the delay exceeds 30 minutes without prior notice, the
            photographer is entitled to cancel the session.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Photo Session Execution</strong>
            </strong>
          </h4>
          <p>
            The primary locations for portrait sessions, family portrait
            sessions and promo shoots are outdoors or at the client's home, as
            agreed with the photographer. In case of inclement weather, an
            outdoor session may be rescheduled. Studio sessions are possible
            with the client covering the rental fee. Entrance fees for shooting
            locations are always the client's responsibility. The photographer
            is not responsible for family members or pets not cooperating; any
            resulting lack of quality shots is not a reason for a fee reduction.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Photo Session Duration</strong>
            </strong>
          </h4>
          <p>
            The duration varies based on the chosen package, with an approximate
            length determined beforehand. If the client wishes to extend the
            session, it's possible with an agreed-upon additional fee.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Price of Photographic Services</strong>
            </strong>
          </h4>
          <p>
            The price for specific types of sessions is agreed upon in advance
            based on the provided quote. If studio rental or premium equipment
            is needed, the rental fees are the client's responsibility.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Payment</strong>
            </strong>
          </h4>
          <p>
            The photo session fee is due on the day of the session, either in
            cash or via bank transfer. Payment for additional products (photo
            prints, albums, etc.) is made via bank transfer before processing.
            If payment is not made on time, the photographer reserves the right
            to withhold the photos until full payment is received.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Transportation</strong>
            </strong>
          </h4>
          <p>
            Travel within Moravskoslezský kraj is free. For Olomoucký kraj,
            Zlínský kraj, Prague or Brno, a transport fee of EUR 20 applies.
            Fees for traveling to different areas are quoted individually.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Selection and Processing of Photos</strong>
            </strong>
          </h4>
          <p>
            Photo selection is done by the photographer, who provides edited
            photos in the agreed-upon format (JPEG). Basic retouching includes
            adjustments to brightness, contrast, color tone, cropping, horizon
            alignment, and minor skin blemish removal. Detailed retouching is
            available for an additional fee per photo (EUR 16). This can be done
            only if it's in accordance with the photographer's values. Extensive
            retouching might be refused. Dissatisfaction with personal
            appearance, photographic style, or the photographer's artistic
            vision is not grounds for complaint.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Delivery</strong>
            </strong>
          </h4>
          <p>
            The delivery timeline is predetermined based on the information in
            the price list or an individual offer. Extended delivery times may
            occur for additional services or products. Photos are delivered
            electronically through the pcloud cloud storage, with the client
            receiving a link for downloading the high-resolution images.
            Unspecified third parties cannot access the photos without the
            specific link.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Photo Archiving</strong>
            </strong>
          </h4>
          <p>
            Unprocessed photos are retained by the photographer for one month
            after photo delivery in accordance with GDPR. Edited photos are
            stored for one year.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Photographic Products</strong>
            </strong>
          </h4>
          <p>
            Printed photos / products are produced by trusted suppliers.
            Complaints for printed photos / products are accepted only for
            visible printing defects (scratches, stripes, and other visible
            flaws), not for color or brightness / darkness discrepancies.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Complaints</strong>
            </strong>
          </h4>
          <p>
            Clients can file complaints for issues such as the non-delivery of
            the promised number of photos or the inability to open photos.
            Complaints about the work of makeup artists are accepted only before
            or during the photo session, not afterward. Client dissatisfaction
            with their appearance is not grounds for photo complaints. Aesthetic
            judgments of photos are subjective, and dissatisfaction with the
            photographer's style is not a reason for complaint. Clients are
            advised to familiarize themselves with the photographer's work
            before selecting them.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Damage Compensation</strong>
            </strong>
          </h4>
          <p>
            Clients are fully responsible for any damage caused to the
            photographer's technical or other equipment by themselves or their
            companions.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Copyright</strong>
            </strong>
          </h4>
          <p>
            All photos are subject to copyright and remain the property of the
            photographer. They cannot be used for any commercial purpose,
            promotion, or sale to a third party without the written consent of
            the author. Individuals photographed may use the photos for personal
            non-commercial presentation unless otherwise agreed upon. Photos
            cannot be edited or published as the author's work without the
            author's consent.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Consent for Photo Publication</strong>
            </strong>
          </h4>
          <p>
            The photographer reserves the right to publish photos in their
            portfolio on social media or websites. If the client does not wish
            their photos to be published, they must inform the photographer in
            advance. Failure to do so implies consent to potential publication.
            The only exception is family sessions. In that case, consent is not
            generated automatically. The photographer must request consent from
            the client. Clients have the right to request the removal of their
            photos from the portfolio.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Personal Data Protection (GDPR)</strong>
            </strong>
          </h4>
          <p>
            For the provision of photography services, some personal data of the
            client is necessary. The photographer strives to minimize the amount
            of collected data and protect it from misuse. Data processing
            complies with legal norms, particularly the Personal Data Protection
            Act. Aneta Jursová, photographer, Frýdek-Místek, ID: 19385277, is
            the data controller.
          </p>
          <h3 id="unordered">
            <strong>
              <strong>Obchodní podmínky</strong>
            </strong>
          </h3>
          <p>Anet Jursová - IČO: 19385277</p>
          <h4 id="unordered">
            <strong>
              <strong>Objednání fotografických služeb</strong>
            </strong>
          </h4>
          <p>
            Je možné e-mailem, zprávou na Instagramu, nebo zprávou na Facebooku.
            Objednáním služeb stvrzuje klient přijetí těchto obchodních podmínek
            a srozumění se s nimi, stejně tak srozumění s autorským přístupem a
            fotografickým stylem, se kterým fotograf pracuje a nemá proti němu
            žádné výhrady. Objednání je závazné v okamžiku odsouhlasení termínu
            focení klientem i fotografem.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Zrušení rezervace</strong>
            </strong>
          </h4>
          <p>
            Pokud se na domluvené focení klient nemůže z jakéhokoliv důvodu
            dostavit a bude požadovat jeho zrušení nebo náhradní termín, je jeho
            povinností tuto skutečnost bez zbytečného odkladu fotografovi
            e-mailem nebo telefonicky oznámit. V takovém případě má fotograf
            nárok na proplacení již vzniklých nákladů v plné výši objednatelem.
            Fotograf je oprávněn zrušit nebo odložit datum fotografování z
            důležitých důvodů (nemoc, úraz apod.). O této skutečnosti bude
            klient včas informován telefonicky nebo prostřednictvím zprávy
            (e-mailem, zprávou na Facebooku, Instagramu nebo SMS). Klientovi
            bude nabídnut nejbližší možný náhradní termín.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Náhradní termín</strong>
            </strong>
          </h4>
          <p>
            Klient bere na vědomí, že pokud bude žádat o náhradní termín, bude
            mu nabídnut nejbližší možný volný termín s ohledem na aktuální
            obsazenost termínů. Může stát, že již žádný vhodný termín na focení
            (zejména u novorozeneckého) mít nemusí.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Pozdní příchod klienta</strong>
            </strong>
          </h4>
          <p>
            O případném zpoždění klient informuje fotografa telefonicky. Pozdní
            příchod není důvodem k prodloužení časového harmonogramu focení.
            Pokud bude zpoždění více než 30 min a bez předchozí omluvy, fotograf
            je oprávněn toto focení zrušit.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Realizace focení</strong>
            </strong>
          </h4>
          <p>
            Portrétní, rodinné a promo focení probíhá primárně v exteriéru, nebo
            u klienta doma. Lokace exteriérového focení je určena po dohodě s
            fotografem. V případě špatného počasí se exteriérové focení přesouvá
            na nejbližší možný náhradní termín. Je možné fotit i v ateliéru.
            Poplatek za pronájem hradí klient. Pokud má focení probíhat v
            místech, kde je vyžadováno vstupné, hradí ho vždy klient. Fotograf
            neručí za nespolupráci rodinných členů a domácích mazlíčků při
            fotografování. V takovém případě fotograf nenese zodpovědnost za
            nedostatek kvalitních záběrů. Stejně tak toto není důvodem ke
            snížení ceny za fotografování.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Délka focení</strong>
            </strong>
          </h4>
          <p>
            Délka focení je individuální a liší se v závislosti na zvoleném
            balíčku. Přibližná délka focení je stanovena předem. Pokud by měl
            klient zájem focení prodloužit a ze strany fotografa by to z
            časových možností bylo proveditelné, je to možné pouze za příplatek
            dohodnutý mezi fotografem a klientem.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Cena fotografických služeb</strong>
            </strong>
          </h4>
          <p>
            Cena konkrétních typů focení je dohodnuta předem dle zaslané
            nabídky. Pokud je požadován pronájem ateliéru, nebo nadstandardní
            techniky, poplatek za pronájem hradí klient.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Splatnost cen</strong>
            </strong>
          </h4>
          <p>
            Cena za focení je splatná v den focení - v hotovosti, nebo bankovním
            převodem. Cena za případné doplňkové produkty (fotoobrazy, fotoknihy
            či tisky fotografií) je formou doplatku zaplacena převodem na účet
            ještě před jejich zpracováním. Nedojde-li k zaplacení ceny za focení
            či případného doplatku za doplňkové služby nad rámec balíčku,
            vyhrazuje si fotograf právo zakázku nepředat do doby, než je řádně
            zaplacena.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Doprava</strong>
            </strong>
          </h4>
          <p>
            Dojezd je v rámci Moravskoslezského kraje zdarma. Při focení v
            Olomouckém kraji, Zlínském kraji, Praze, nebo Brně je účtováno
            cestovné 500 Kč. V případě cestování mimo tyto oblasti se cena
            dopravy domlouvá individuálně.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Výběr a zpracování fotografií</strong>
            </strong>
          </h4>
          <p>
            Výběr fotografií provádí fotograf. Fotograf neposkytuje neupravené
            fotografie. Zpracované fotografie budou dodány v domluveném rozsahu
            ve formátu JPEG. Všechny vybrané fotografie procházejí základní
            retuší. Upravuje se například jas, kontrast, barevná tonalita, ořez
            fotky, srovnání horizontu a odstraňují se drobné kosmetické vady na
            pleti. V případě, že bude klient požadovat detailnější a náročnější
            retuš u vybraných fotek, pak je tato služba poskytována za příplatek
            400 Kč za každou fotografii, je-li to v souladu s hodnotami
            fotografa. Požadavky na extrémní změny mohou být proto zamítnuty.
            Nespokojenost s vlastním vzhledem, fotografickým stylem a autorským
            pojetím fotografa není důvodem pro reklamaci.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Dodání fotografií</strong>
            </strong>
          </h4>
          <p>
            Lhůta dodání je sjednána předem na základě informací v základním
            ceníku / individuální nabídky. Dodací lhůta může být prodloužena v
            případě objednání nadstandardních služeb - např. komplexnější retuš,
            objednání fotografických produktů. Fotografie jsou klientovi
            předávány v elektronické podobě prostřednictvím cloudového úložiště
            pcloud. Klient obdrží link, ze kterého si může fotky stáhnout v
            plném rozlišení do počítače. Bez konkrétního linku si fotografie
            nemůže prohlížet třetí strana. Záleží tedy jen na klientovi, komu
            link poskytne. Fotografie jsou odevzdávány ve formátu JPEG.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Archivace fotografií</strong>
            </strong>
          </h4>
          <p>
            Nezpracované fotografie uchovává fotograf po dobu 1 měsíce od
            odevzdání fotografií v souladu s nařízením EU 2016/679 o ochraně
            fyzických osob (tzv. GDPR), klient tak bere na vědomí, že případné
            reklamace po tomto datu již nelze přijmout. Upravené fotografie jsou
            uchovávány po dobu 1 roku.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Fotografické produkty</strong>
            </strong>
          </h4>
          <p>
            Fotografie jsou vyvolávány u ověřených dodavatelů. Reklamace
            tištěných fotografií / fotoproduktů je možná pouze na základě
            viditelné tiskové chyby (škrábance, pruhy a jiné viditelné vady),
            nikoli v barevnosti či světlosti / tmavosti daného tisku.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Reklamace</strong>
            </strong>
          </h4>
          <p>
            Klient může reklamovat například nedodání slíbeného počtu fotografií
            nebo nemožnost otevřít fotografie. Reklamace na práci vizážistů je
            možná pouze před samotným focením, případně v jeho průběhu. Po
            skončení focení už nikoliv. Pokud není klient spokojen s vlastním
            vzhledem, není to důvod k reklamaci fotografií. Estetická kvalita
            fotografií nemůže být předmětem reklamace. Pohledy na fotografii
            mohou být různé a její hodnocení je ryze subjektivním aspektem.
            Pokud není klient spokojen s fotografickým stylem fotografa, není to
            důvod k reklamaci. Dle Obchodních podmínek se má klient předem
            seznámit s prací fotografa, kterého si pro svou zakázku vybere.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Náhrada škody</strong>
            </strong>
          </h4>
          <p>
            Pokud klient a jeho doprovod způsobí škodu na technickém či jiném
            vybavení fotografa, klient plně odpovídá za jejich úhradu.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Autorská práva</strong>
            </strong>
          </h4>
          <p>
            Všechny fotografie jsou předmětem autorského práva a majetkem
            fotografa a nesmí být použity pro jakýkoli komerční účel, propagaci
            či prodej třetí straně bez písemného souhlasu autora. Fotografované
            osoby mohou používat fotografie pro svou nekomerční osobní
            prezentaci, není-li smluveno jinak. Dle autorského zákona č.
            121/2000 Sb. není bez souhlasu autora dovoleno fotografie jakkoliv
            upravovat (např. použití barevných filtrů na instagramu) a
            zveřejňovat jako dílo autora.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Souhlas se zveřejněním fotografií</strong>
            </strong>
          </h4>
          <p>
            Fotograf si vyhrazuje právo ke zveřejnění fotografií do svého
            portfolia na sociálních sítích nebo na svých webových stránkách.
            Pokud si klient nepřeje, aby jeho fotografie byly zveřejněny, je
            nutné o tom fotografa předem informovat. Pokud tak klient neučiní,
            souhlasí s možným zveřejněním. Výjimkou je rodinné focení. Tam
            souhlas nevzniká automaticky. Fotograf si musí souhlas od klienta
            vyžádat. Klient má právo dodatečně požadovat odstranění fotografií z
            portfolia.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Ochrana osobních údajů (GDPR)</strong>
            </strong>
          </h4>
          <p>
            Pro poskytování služeb fotografa je třeba znát některé osobní data
            klienta. Fotograf se snaží množství těchto sbíraných dat
            minimalizovat a chránit je před zneužitím. Práce s daty podléhá
            zákonným normám, zejména zákonu o ochraně osobních údajů. Správcem
            osobních údajů je Aneta Jursová, fotograf, Frýdek-Místek, IČO:
            19385277.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    smallPic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medPic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    largePic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactsPage Page location={props.location} data={data} {...props} />
    )}
  />
)
